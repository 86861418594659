<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form action="#" @submit.prevent="submit">
          <div class="form-body">
            <h3 class="card-title">Retensi Arsip</h3>
            <hr>

            <div class="row p-t-20">
              
              <div class="col-md-4">
                <label :class="['control-label']">
                  Jenis Surat
                  <small style="color:red">*</small>
                </label>
                <div :class="[{ 'invalid': isInvalidTypeSurat }]">
                  <Multiselect
                    v-model="payload.id_typesurat"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Type Surat"
                    open-direction="bottom"
                    :options="dropdownTypeSurat.tipesuratList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownTypeSurat.value"
                    :loading="dropdownTypeSurat.isLoadingTypeSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchTypeSurat"
                    @input="onChangeTypeSurat"
                    @search-change="typeSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidTypeSurat"
                  >Must have at least one value</label>
                </div>
              </div>
              
            </div>

            <div class="row">

              <div class="col-md-12 m-t-10 m-b-10">
                <label>
                  Retensi Arsip
                  <small style="color:red;">*</small>
                </label>
                <div>
                  <Input v-model="payload.asalsurat" name="asalsurat" required type="text" />
                </div>
              </div>

            </div>

            <hr class="m-t-40">

          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-check"></i> Save
            </button> &nbsp;
            <button type="button" @click="goBack" class="btn btn-inverse">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import store from "@/store";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.retensiArsip;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidTypeSurat() {
      return (
        this.dropdownTypeSurat.isTouched &&
        this.dropdownTypeSurat.value.length === 0
      );
    }

  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        id_typesurat: [],
        retensiarsip: ""
      },

      dropdownTypeSurat: {
        isTouched: false,
        tyratList: [],
        value: [],
        isLoadingTypeSurat: false
      },
      myHTML: "",
      myFile: [],
      isDisabled: false
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
  },

  methods: {
    getAllReference() {
      var query = "";
      this.typeSurat(query);
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/retensiArsip/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("retensiArsip/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("retensiArsip/onAdd");
          // axios.get(`/document_in/new`).then(response => {
          //   // this.payload.agenda = response.data.data.agenda_number;
          //   // this.payload.tanggal = response.data.data.document_date;
          // });
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        id_typesurat: data.document_typesurat,
        asal: data.document_asalsurat
      };
      this.myHTML = data.description;
    },
    goBack() {
      this.$store.dispatch("retensiArsip/onCancel");
    },
    submit() {
      if (
        this.dropdownTypeSurat.value.length === 0
      ) {
        this.dropdownTypeSurat.isTouched = true;
        window.scrollTo(0, 0);
      }
      if (this.payload.file) {
        this.myFile = this.payload.file.split(",");
      }
      const payload = {
        id_typesurat: this.payload.id_typesurat,
        asalsurat: this.payload.asalsurat,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (
          success &&
          !this.isInvalidTypeSurat
        ) {
          if (this.editMode) {
            this.$store.dispatch("retensiArsip/submitEdit", {
              data,
              id: this.$route.params.id
            });
          } else {
            this.$store.dispatch("retensiArsip/submitAdd", data);
          }
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },

    typeSurat(query) {
      this.dropdownTypeSurat.isLoadingTypeSurat = true;
      axios
        .get(`typeSurat/list?s=${query}`)
        .then(res => {
          this.dropdownTypeSurat.tipesuratList = res.data.items;
          this.dropdownTypeSurat.isLoadingTypeSurat = false;
        })
        .catch(err => {
          if (err.response.status === 401) {
            store.commit("auth/LOGOUT");
          }
        });
    },
    onTouchTypeSurat() {
      this.dropdownTypeSurat.isTouched = true;
    }, 
    onChangeTypeSurat(value) {
      this.dropdownTypeSurat.value = value;
    },
    nomorDokumen(value) {
      this.payload.nomor_dokumen = value.document_number;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

